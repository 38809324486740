const IMAGES = {
    backgrounds: {
      fadedBars: require("../assets/images/backgrounds/faded-bars-background.png"),
      fadedDashes: require("../assets/images/backgrounds/faded-dashes-background.png"),
      fadedHexagons: require("../assets/images/backgrounds/faded-hexagons-background.png"),
      fadedLinearGradients: require("../assets/images/backgrounds/faded-linear-gradients-background.png"),
      fadedRadialGradients: require("../assets/images/backgrounds/faded-radial-gradients-background.png")
    },
    common: {
        navArrow: require('../assets/images/navbar/nav_arrow.png'),
        cswapLogo: require('../assets/images/navbar/green-logo.svg'),
        loader: require('../assets/images/loader.svg'),
        downArrow: require('../assets/images/navbar/down-arrow.svg'),
        errorLogo: require('../assets/images/error-img.png'),
    },
    cswapinfo: {
        decentralizedImage: require('../assets/images/cswapinfo/decentralized.svg'),
        tradingImage: require('../assets/images/cswapinfo/trading.svg'),
        farmsImage: require('../assets/images/cswapinfo/farms.svg'),
    },
    sidebar: {
        telegramLogo: require('../assets/images/sidebar/telegram_logo.svg'),
        twitterLogo: require('../assets/images/sidebar/twitter_logo.svg'),
        mediumLogo: require('../assets/images/sidebar/medium_logo.svg'),
        redditLogo: require('../assets/images/sidebar/reddit_logo.svg'),
        youtubeLogo: require('../assets/images/sidebar/youtube_logo.svg'),
        githubLogo: require('../assets/images/sidebar/github_logo.svg'),
        discordLogo: require('../assets/images/sidebar/discord.svg'),
    },
    heroSection: {
        cardanoLogo: require("../assets/images/hero/cardano-logo.png"),
        heroBlocksImage: require('../assets/images/hero/hero-img.png'),
        poweredLogo: require('../assets/images/hero/powered_by_cardano.png'),
        heroTextImage: require('../assets/images/hero/herotext.svg'),
        navigationIcon: require('../assets/images/hero/navigation-arrow.svg'),
        heroBgImage: require('../assets/images/hero/hero-bg.png'),
        discordIcon: require('../assets/images/hero/discord.svg'),
        namiIcon: require('../assets/images/hero/nami.png'),
        geroWalletIcon: require('../assets/images/hero/gero-wallet.png'),
        eternlIcon: require('../assets/images/hero/eternl.png'),
        flintIcon: require('../assets/images/hero/flint.png'),
    },
    builtWithTrustedPartners: {
      blockfrost_white: require("../assets/icons/built-with-trusted-partners/partner-icon-blockfrost-white.svg"),
      blockfrost_greyscale: require("../assets/icons/built-with-trusted-partners/partner-icon-blockfrost-greyscale.svg"),
      catalyst_white: require("../assets/icons/built-with-trusted-partners/partner-icon-catalyst-white.svg"),
      catalyst_greyscale: require("../assets/icons/built-with-trusted-partners/partner-icon-catalyst-greyscale.svg"),
      nftcdn_white: require("../assets/icons/built-with-trusted-partners/partner-icon-nftcdn-white.svg"),
      nftcdn_greyscale: require("../assets/icons/built-with-trusted-partners/partner-icon-nftcdn-greyscale.svg"),
      taptools_white: require("../assets/icons/built-with-trusted-partners/partner-icon-taptools-white.svg"),
      taptools_greyscale: require("../assets/icons/built-with-trusted-partners/partner-icon-taptools-greyscale.svg"),
    },
    partner: {
        apeNationImage: require('../assets/images/partner/ape-nation.png'),
        nonFungibleImage: require('../assets/images/partner/non-fungible.png'),
        infoIcon: require('../assets/images/partner/info.svg'),
        discordIcon: require('../assets/images/partner/discord.svg'),
        twitterIcon: require('../assets/images/partner/twitter.svg'),
        globeIcon: require('../assets/images/partner/globe.svg'),
    },
    roadmap: {
        roadmapLeftBg: require('../assets/images/roadmap/roadmap-left-bg.svg'),
        roadmapRightBg: require('../assets/images/roadmap/roadmap-right-bg.svg'),
        checkMark: require('../assets/images/roadmap/checkmark.svg'),
        flag: require('../assets/images/roadmap/flag.svg'),
        loader: require('../assets/images/roadmap/loading.svg'),
        starGreen: require('../assets/images/roadmap/star-green.svg'),
        starBlue: require('../assets/images/roadmap/star-blue.svg'),
        starOrange: require('../assets/images/roadmap/star-orange.svg'),
        starPurple: require('../assets/images/roadmap/star-purple.svg'),
        checkMarkBlue: require('../assets/images/roadmap/checkmark-blue.svg'),
        checkMarkGreen: require('../assets/images/roadmap/checkmark-green.svg'),
        checkMarkOrange: require('../assets/images/roadmap/checkmark-orange.svg'),
        checkMarkPurple: require('../assets/images/roadmap/checkmark-purple.svg'),
        loaderOrange: require('../assets/images/roadmap/loading-orange.svg'),
        loaderPurple: require('../assets/images/roadmap/loading-purple.svg'),
        loaderBlue: require('../assets/images/roadmap/loading-blue.svg'),
        loaderGreen: require('../assets/images/roadmap/loading-green.svg'),
    },
    nftfi: {
        nft: require('../assets/images/nftfi/nft.svg'),
        cnft: require('../assets/images/nftfi/cnft.png'),
        btnArrow: require('../assets/images/hero/button_arrow.png'),
    },
    wallets: {
        yoroiImage: require('../assets/images/wallets/yoroi.svg'),
        namiImage: require('../assets/images/wallets/nami.svg'),
        geroWalletImage: require('../assets/images/wallets/gero-wallet.svg'),
        eternlImage: require('../assets/images/wallets/eternl.svg'),
        flintImage: require('../assets/images/wallets/flint.svg'),
    },
    joinOurCommunity: {
      x: require("../assets/icons/join-our-community/community-icon-x.svg"),
      github: require("../assets/icons/join-our-community/community-icon-github.svg"),
      medium: require("../assets/icons/join-our-community/community-icon-medium.svg"),
      discord: require("../assets/icons/join-our-community/community-icon-discord.svg"),
      linkedin: require("../assets/icons/join-our-community/community-icon-linkedin.svg"),
      telegram: require("../assets/icons/join-our-community/community-icon-telegram.svg")
    },
    community: {
        discordIcon: require('../assets/images/joinCommunity/discord.svg'),
        telegramIcon: require('../assets/images/joinCommunity/telegram.svg'),
        twitterIcon: require('../assets/images/joinCommunity/twitter.svg'),
        redditIcon: require('../assets/images/joinCommunity/reddit.svg'),
        youtubeIcon: require('../assets/images/joinCommunity/youtube.svg'),
        mediumIcon: require('../assets/images/joinCommunity/medium.svg'),
        githubIcon: require('../assets/images/joinCommunity/github.svg'),
        budgeIcon: require('../assets/images/joinCommunity/budgeImg.png'),
    },
    footer: {
      x: require("../assets/icons/footer/footer-icon-x.svg"),
      github: require("../assets/icons/footer/footer-icon-github.svg"),
      medium: require("../assets/icons/footer/footer-icon-medium.svg"),
      discord: require("../assets/icons/footer/footer-icon-discord.svg"),
      linkedin: require("../assets/icons/footer/footer-icon-linkedin.svg"),
      telegram: require("../assets/icons/footer/footer-icon-telegram.svg")
    },
    rewards: {
        calcImage: require('../assets/images/rewardIspo/calculator.svg'),
        reloadImage: require('../assets/images/rewardIspo/refresh-img.svg'),
        infoImg: require('../assets/images/rewardIspo/info-icon.svg'),
        questionImg: require('../assets/images/rewardIspo/question-icon.svg'),
        RewardBg: require('../assets/images/rewardIspo/hero_bg.png'),
    },
    rewardsCalc: {
        bronzeImage: require('../assets/images/rewardsCalc/bronze.png'),
        PoolImage1: require('../assets/images/rewardsCalc/pool-img1.svg'),
        PoolImage2: require('../assets/images/rewardsCalc/pool-img2.svg'),
        bronze: require('../assets/images/rewardsCalc/bronze.svg'),
        silver: require('../assets/images/rewardsCalc/silver.svg'),
        gold: require('../assets/images/rewardsCalc/gold.svg'),
        platinum: require('../assets/images/rewardsCalc/platinum.svg'),
        arrowImage: require('../assets/images/rewardsCalc/number-arrow.svg'),
    },
    incentives: {
        earlyBird: require('../assets/images/ispo/early-bird.png'),
        events: require('../assets/images/ispo/events.png'),
        cswapNft: require('../assets/images/ispo/nft.png'),
        shapeImg: require('../assets/images/ispo/title-img.svg'),
        bookImg: require('../assets/images/ispo/book-icon.png'),
    },
    supportedPool: {
        arrow: require('../assets/images/ispo/arrow.svg'),
    },
    participate: {
        copyIcon: require('../assets/images/participate/copy.svg'),
        copySuccess: require('../assets/images/participate/success.svg'),
        exportIcon: require('../assets/images/participate/export.svg'),
    },
    contactUs: {
        leftBgContactUs: require('../assets/images/contact/contactLeftShape.svg'),
        rightBgContactUs: require('../assets/images/contact/contactRightShape.svg'),
    },
    ispoIncentives: {
        ispoRightBg: require('../assets/images/ispo/ispo-rgt-shape.svg'),
        ispoLeftBg: require('../assets/images/ispo/ispo-lft-shape.svg'),
    },
    ispoBasics: {
        shapeImg: require('../assets/images/ispo/title-img.svg'),
        nftImg: require('../assets/images/ispo/ngt-icon.svg'),
        cswapLogoImg: require('../assets/images/ispo/cswap-icon.svg'),
    },
    nftsStacking: {
        discordIcon: require('../assets/images/nftsStaking/Discord.svg'),
        stackingImg: require('../assets/images/nftsStaking/staking-img.png'),
        sendIcon: require('../assets/images/nftsStaking/send-icon.svg'),
        twitterIcon: require('../assets/images/nftsStaking/twitter-icon.svg'),
        jpgIcon: require('../assets/images/nftsStaking/store.svg'),
        nftiBgImage: require('../assets/images/nftsStaking/nfts-bg.png'),
        gitBook: require('../assets/images/nftsStaking/gitbook.svg'),
        utilityImg: require('../assets/images/nftsStaking/nfti-utility-img.png'),
    },
    nftsOrigin: {
        originImg: require('../assets/images/nftsOrigin/cswap-origins.svg'),
        underDog: require('../assets/images/nftsOrigin/under-dog.svg'),
        villains: require('../assets/images/nftsOrigin/villains.svg'),
        sliderImg1: require('../assets/images/nftsOrigin/BH-Dracula-Gold.png'),
        sliderImg2: require('../assets/images/nftsOrigin/BH-Medusa-Gold.png'),
        sliderImg3: require('../assets/images/nftsOrigin/BH-Hades-Gold.png'),
        sliderImg4: require('../assets/images/nftsOrigin/BH-Grim-Gold.png'),
        sliderImg5: require('../assets/images/nftsOrigin/BH-Pharaoh-Gold.png'),
        nextArrow: require('../assets/images/nftsOrigin/next-icon.svg'),
        prevArrow: require('../assets/images/nftsOrigin/prev-icon.svg'),
        originBg: require('../assets/images/nftsOrigin/origin-bg.png'),
        renegades_logo: require("../assets/images/nftsOrigin/renegades_logo.png"),
        renegades_robin_hood: require("../assets/images/nftsOrigin/renegades_robin_hood.png"),
        renegades_william_wallace: require("../assets/images/nftsOrigin/renegades_william_wallace.png"),
        renegades_billy_the_kid: require("../assets/images/nftsOrigin/renegades_billy_the_kid.png"),
        renegades_joan_of_arc: require("../assets/images/nftsOrigin/renegades_joan_of_arc.png"),
        renegades_anonymous: require("../assets/images/nftsOrigin/renegades_anonymous.png")
    },
    nftsCollection: {
        budgeImg: require('../assets/images/nftsCollection/badge-icon.png'),
        underDogImg: require('../assets/images/nftsCollection/under-dog.png'),
        underDogImgSmall: require('../assets/images/nftsCollection/underdogbannersmall.png'),
        villainsImg: require('../assets/images/nftsCollection/villainsbanner1.png'),
        underDogMobileImg: require('../assets/images/nftsCollection/under-dog-mobile.png'),
        underDogImgMob: require('../assets/images/nftsCollection/under-dog-mobile.png'),
        goldenBox: require('../assets/images/nftsCollection/gold-box.png'),
        platinumBox: require('../assets/images/nftsCollection/platinum-box.png'),
        silverBox: require('../assets/images/nftsCollection/silver-box.png'),
        q1_2023: require("../assets/images/nftsCollection/q1_2023.png"),
        q4_2024: require("../assets/images/nftsCollection/q4_2024.png")
    },
    cswapScan: {
        copyIcon: require('../assets/images/cswapScan/copy.svg'),
        prevForward: require('../assets/images/cswapScan/prev-forward.svg'),
        prevIcon: require('../assets/images/cswapScan/prev.svg'),
        nextForward: require('../assets/images/cswapScan/next-forward.svg'),
        nextIcon: require('../assets/images/cswapScan/next.svg'),
    },
    meetOurTeam: {
      outlineAqua: require("../assets/images/meet-our-team/member-outline-aqua.png"),
      outlineBlue: require("../assets/images/meet-our-team/member-outline-blue.png"),
      outlineGreen: require("../assets/images/meet-our-team/member-outline-green.png"),
      outlineOrange: require("../assets/images/meet-our-team/member-outline-orange.png"),
      outlinePink: require("../assets/images/meet-our-team/member-outline-pink.png"),
      outlinePurple: require("../assets/images/meet-our-team/member-outline-purple.png"),
      outlineRed: require("../assets/images/meet-our-team/member-outline-red.png"),
      outlineYellow: require("../assets/images/meet-our-team/member-outline-yellow.png"),

      emblemAqua: require("../assets/images/meet-our-team/member-emblem-aqua.png"),
      emblemBlue: require("../assets/images/meet-our-team/member-emblem-blue.png"),
      emblemGreen: require("../assets/images/meet-our-team/member-emblem-green.png"),
      emblemOrange: require("../assets/images/meet-our-team/member-emblem-orange.png"),
      emblemPink: require("../assets/images/meet-our-team/member-emblem-pink.png"),
      emblemPurple: require("../assets/images/meet-our-team/member-emblem-purple.png"),
      emblemRed: require("../assets/images/meet-our-team/member-emblem-red.png"),
      emblemYellow: require("../assets/images/meet-our-team/member-emblem-yellow.png"),

      photoJonKravetz: require("../assets/images/meet-our-team/member-photo-jon-kravetz.png"),
      photoEzekielSmigel: require("../assets/images/meet-our-team/member-photo-ezekiel-smigel.png"),
      photoFranklinChua: require("../assets/images/meet-our-team/member-photo-franklin-chua.png"),
      photoMarkWilliams: require("../assets/images/meet-our-team/member-photo-mark-williams.png"),

      socialIconX: require("../assets/icons/meet-our-team/social-icon-x.svg"),
      socialIconXAqua: require("../assets/icons/meet-our-team/social-icon-x-aqua.svg"),
      socialIconXBlue: require("../assets/icons/meet-our-team/social-icon-x-blue.svg"),
      socialIconXGreen: require("../assets/icons/meet-our-team/social-icon-x-green.svg"),
      socialIconXOrange: require("../assets/icons/meet-our-team/social-icon-x-orange.svg"),
      socialIconXPink: require("../assets/icons/meet-our-team/social-icon-x-pink.svg"),
      socialIconXPurple: require("../assets/icons/meet-our-team/social-icon-x-purple.svg"),
      socialIconXRed: require("../assets/icons/meet-our-team/social-icon-x-red.svg"),
      socialIconXYellow: require("../assets/icons/meet-our-team/social-icon-x-yellow.svg"),

      socialIconLinkedIn: require("../assets/icons/meet-our-team/social-icon-linkedin.svg"),
      socialIconLinkedInAqua: require("../assets/icons/meet-our-team/social-icon-linkedin-aqua.svg"),
      socialIconLinkedInBlue: require("../assets/icons/meet-our-team/social-icon-linkedin-blue.svg"),
      socialIconLinkedInGreen: require("../assets/icons/meet-our-team/social-icon-linkedin-green.svg"),
      socialIconLinkedInOrange: require("../assets/icons/meet-our-team/social-icon-linkedin-orange.svg"),
      socialIconLinkedInPink: require("../assets/icons/meet-our-team/social-icon-linkedin-pink.svg"),
      socialIconLinkedInPurple: require("../assets/icons/meet-our-team/social-icon-linkedin-purple.svg"),
      socialIconLinkedInRed: require("../assets/icons/meet-our-team/social-icon-linkedin-red.svg"),
      socialIconLinkedInYellow: require("../assets/icons/meet-our-team/social-icon-linkedin-yellow.svg"),
    },
    coreTeam: {
        jonKravetz: require('../assets/images/team/jon-kravetz.png'),
        markWilliams: require('../assets/images/team/mark-williams.png'),
        m: require('../assets/images/advisors/kyle.png'),
        buzzPluto: require('../assets/images/advisors/kenneth-yoffy.png'),
        eileen: require('../assets/images/team/eileen.png'),
        franklin: require('../assets/images/team/franklin-chua.png'),
        anon: require('../assets/images/team/anon.png'),
        anonUX: require('../assets/images/team/anon-UX.png'),
        shapeImg: require('../assets/images/team/shape-bg.png'),
        linkedin: require('../assets/images/team/linkedin.svg'),
        twitter: require('../assets/images/team/twitter.svg'),
    },
    advisors: {
        anon: require('../assets/images/advisors/anon.png'),
        kc: require('../assets/images/advisors/kc.png'),
        kyle: require('../assets/images/advisors/kyle.png'),
        jimKekatos: require('../assets/images/advisors/jim-kekatos.png'),
        kennethYoffy: require('../assets/images/advisors/kenneth-yoffy.png'),
        garyGreen: require('../assets/images/advisors/gary-green.png'),
        marianFord: require('../assets/images/advisors/marian-ford.png'),
    },
    nav: {
      hamburgerIcon: require("../assets/icons/nav/hamburger-nav-icon.svg"),
      closeIcon: require("../assets/icons/nav/close-nav-icon.svg"),
    },
    whatMakesCSWAPDifferent: {
      cswapEmblemAqua: require("../assets/icons/what-makes-cswap-different/cswap-emblem-aqua.svg"),
      cswapEmblemBlue: require("../assets/icons/what-makes-cswap-different/cswap-emblem-blue.svg"),
      cswapEmblemOrange: require("../assets/icons/what-makes-cswap-different/cswap-emblem-orange.svg"),
      cswapEmblemPurple: require("../assets/icons/what-makes-cswap-different/cswap-emblem-purple.svg"),
      cswapEmblemRed: require("../assets/icons/what-makes-cswap-different/cswap-emblem-red.svg"),
      cswapEmblemSilver: require("../assets/icons/what-makes-cswap-different/cswap-emblem-silver.svg")
    },
    tokenomics: {
      small: require("../assets/images/tokenomics/tokenomics-pie-chart-small.png"),
      large: require("../assets/images/tokenomics/tokenomics-pie-chart-large.png")
    }
}

export default IMAGES;
